
// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n !=null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints)==null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  }

  @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @else if $max==null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  }

  @else if $min==null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @else if $max==null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  }

  @else if $min==null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}



$grid-columns     : 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (xs: 0,
  s: 250px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1150px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px,
) !default;

$grid-breakpoints-flowee: (xs, s, sm, md, lg, xl, xxl, xxxl, xxxxl);

$fsizes: 0,
0.1,
0.2,
0.3,
0.4,
0.5,
0.6,
0.7,
0.8,
0.9;
$min-font-size: 0;
$max-font-size: 50;

$padsizes: ("0": 0,
  "1": 0.25,
  "2": 0.5,
  "3": 1,
  "4": 1.5,
  "5": 3,
);
$gridsizes: ("1": 8.3333333333,
  "2": 16.6666666667,
  "3": 25,
  "4": 33.3333333333,
  "5": 41.6666666667,
  "6": 50,
  "7": 58.3333333333,
  "8": 66.6666666667,
  "9": 75,
  "10": 83.3333333333,
  "11": 91.6666666667,
  "12": 100,
);


@for $i from $min-font-size through $max-font-size {
  @each $ssize in $fsizes {
    .fsp-#{$i}-#{$ssize*10} {
      font-size: 0px + $i + $ssize !important;
    }
  }
}

@each $break in $grid-breakpoints-flowee {
  @include media-breakpoint-up($break) {
    @for $i from $min-font-size through $max-font-size {
      @each $ssize in $fsizes {
        .fsp-#{$break}-#{$i}-#{$ssize*10} {
          font-size: 0px + $i + $ssize !important;
        }
      }
    }
  }
}

@each $i,
$rem in $padsizes {
  .ps-p-#{$i} {
    padding: 0em + $rem !important;
  }

  .ps-pl-#{$i} {
    padding-left: 0em + $rem !important;
  }

  .ps-pr-#{$i} {
    padding-right: 0em + $rem !important;
  }

  .ps-pt-#{$i} {
    padding-top: 0em + $rem !important;
  }

  .ps-pb-#{$i} {
    padding-bottom: 0em + $rem !important;
  }

  .ps-px-#{$i} {
    padding-left : 0em + $rem !important;
    padding-right: 0em + $rem !important;
  }

  .ps-py-#{$i} {
    padding-top   : 0em + $rem !important;
    padding-bottom: 0em + $rem !important;
  }

  .ps-m-#{$i} {
    margin: 0em + $rem !important;
  }

  .ps-ml-#{$i} {
    margin-left: 0em + $rem !important;
  }

  .ps-mr-#{$i} {
    margin-right: 0em + $rem !important;
  }

  .ps-mt-#{$i} {
    margin-top: 0em + $rem !important;
  }

  .ps-mb-#{$i} {
    margin-bottom: 0em + $rem !important;
  }

  .ps-mx-#{$i} {
    margin-left : 0em + $rem !important;
    margin-right: 0em + $rem !important;
  }

  .ps-my-#{$i} {
    margin-top   : 0em + $rem !important;
    margin-bottom: 0em + $rem !important;
  }
}

@each $i,
$perc in $gridsizes {
  .ps-col-#{$i} {
    flex         : 0 0 0% + $perc !important;
    max-width    : 0% + $perc !important;
    position     : relative;
    width        : 100%;
    padding-right: 15px;
    padding-left : 15px;
  }
}

@each $break in $grid-breakpoints-flowee {
  @include media-breakpoint-up($break) {

    @each $i,
    $perc in $gridsizes {
      .ps-col-#{$break}-#{$i} {
        flex         : 0 0 0% + $perc !important;
        max-width    : 0% + $perc !important;
        position     : relative;
        width        : 100%;
        padding-right: 15px;
        padding-left : 15px;
      }
    }
  }
}

@each $break in $grid-breakpoints-flowee {
  @include media-breakpoint-up($break) {

    @each $i,
    $rem in $padsizes {
      .ps-p-#{$break}-#{$i} {
        padding: 0em + $rem !important;
      }

      .ps-pl-#{$break}-#{$i} {
        padding-left: 0em + $rem !important;
      }

      .ps-pr-#{$break}-#{$i} {
        padding-right: 0em + $rem !important;
      }

      .ps-pt-#{$break}-#{$i} {
        padding-top: 0em + $rem !important;
      }

      .ps-pb-#{$break}-#{$i} {
        padding-bottom: 0em + $rem !important;
      }

      .ps-m-#{$break}-#{$i} {
        margin: 0em + $rem !important;
      }

      .ps-ml-#{$break}-#{$i} {
        margin-left: 0em + $rem !important;
      }

      .ps-mr-#{$break}-#{$i} {
        margin-right: 0em + $rem !important;
      }

      .ps-mt-#{$break}-#{$i} {
        margin-top: 0em + $rem !important;
      }

      .ps-mb-#{$break}-#{$i} {
        margin-bottom: 0em + $rem !important;
      }

      .ps-mx-#{$break}-#{$i} {
        margin-left : 0em + $rem !important;
        margin-right: 0em + $rem !important;
      }

      .ps-my-#{$break}-#{$i} {
        margin-top   : 0em + $rem !important;
        margin-bottom: 0em + $rem !important;
      }
    }
  }
}

.ps-d-flex {
  display: flex !important;
}

.ps-d-block {
  display: block !important;
}

.ps-d-none {
  display: none !important;
}

@each $break in $grid-breakpoints-flowee {
  @include media-breakpoint-up($break) {


    .ps-d-#{$break}-flex {
      display: flex !important;
    }

    .ps-d-#{$break}-block {
      display: block !important;
    }

    .ps-d-#{$break}-none {
      display: none !important;
    }

  }
}


.ps-align-items-start {
  align-items: flex-start !important;
}

.ps-align-items-end {
  align-items: flex-end !important;
}

.ps-align-items-center {
  align-items: center !important;
}

.ps-justify-content-around {
  justify-content: space-around !important;
}

.ps-justify-content-center {
  justify-content: center !important;
}

.ps-justify-content-between {
  justify-content: space-between !important;
}

.ps-justify-content-end {
  justify-content: flex-end !important;
}

@each $break in $grid-breakpoints-flowee {
  @include media-breakpoint-up($break) {


    .ps-justify-content-#{$break}-end {
      justify-content: flex-end !important;
    }

    .ps-justify-content-#{$break}-start {
      justify-content: flex-start !important;
    }

    .ps-justify-content-#{$break}-center {
      justify-content: center !important;
    }

    .ps-justify-content-#{$break}-between {
      justify-content: space-between !important;
    }

    .ps-align-items-#{$break}-start {
      align-items: flex-start !important;
    }

    .ps-align-items-#{$break}-center {
      align-items: center !important;
    }

    .ps-align-items-#{$break}-end {
      align-items: flex-end !important;
    }

  }
}

.ps-ml-auto {
  margin-left: auto;
}

.ps-mr-auto {
  margin-right: auto;
}

.fw-b {
  font-weight: 700 !important;
}

.fw-sb {
  font-weight: 600 !important;
}

.fw-r {
  font-weight: 400 !important;
}

.fw-l {
  font-weight: 200 !important;
}

.href-reset {
  color          : unset !important;
  text-decoration: none !important;
}

.oy-auto {
  overflow-y: auto;
}

.oy-scroll {
  overflow-y: scroll;
}

.oy-hidden {
  overflow-y: hidden;
}

.ox-auto {
  overflow-x: auto;
}

.ox-scroll {
  overflow-x: scroll;
}

.ox-hidden {
  overflow-x: hidden;
}

.ps-primary-color {
  color: $primary-color;
}

.ps-purple-color {
  color: #954392;
}

.ps-color-black {
  color: black;
}

.ps-cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.ps-w-100 {
  width: 100% !important;
}

.ps-flex-wrap {
  flex-wrap: wrap !important;
}

.ps-h-100 {
  height: 100% !important;
}

.ps-align-self-start {
  align-self: flex-start !important;
}

.ps-align-self-center {
  align-self: center !important;
}

.ps-oy-auto {
  overflow-y: auto !important;
}

.ps-mr-auto {
  margin-right: auto !important;
}

.ps-ml-auto {
  margin-left: auto !important;
}

.ps-mt-auto {
  margin-top: auto !important;
}

.ps-mb-auto {
  margin-bottom: auto !important;
}

.ps-text-center {
  text-align: center !important;
}

.ps-text-upper {
  text-transform: uppercase !important;
}

.ps-text-capitilize {
  text-transform: capitalize !important;
}

.ps-text-initial {
  text-transform: initial !important;
}

.ps-position-relative{
  position: relative;
}
