//Primary color
$primary-color: rgba(235, 191, 62,1);

//typography
$title-color: #000000;
$background-color: #fdfdfd;

//Footer
$footer-background-color-top: #000000;
$footer-background-color-bottom: #1e1d1d;

//Header
$header-background-color: #ffffff;

//scrollbar
$scrollbar-background-color: #f7f7f7;
$scrollbar-color: rgba(235, 191, 62,.9);
$scrollbar-background-shadow-color: #f7f7f7;

//animations
$animationY: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1.2);
$animationX: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1);