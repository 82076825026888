.edit-text-mode{
  border: 1px solid green !important;
  z-index: 99;
  cursor: url('../../../assets/cursor/cursor-edit.png'), auto !important;
}

.edit-image-mode{
  border: 1px solid red !important;
  cursor: url('../../../assets/cursor/cursor-edit.png'), auto !important;
}

